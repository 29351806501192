import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { GoMail } from "react-icons/go";
import { FaWhatsapp } from "react-icons/fa";



const Footer = () => {
  return (
    <Navbar
      expand="lg"
      style={{ backgroundColor: "#343a40", display: "block", color: "#ffffff" }}
    >
      <Container
        style={{
          marginTop: '10px',
          display: "flex",
          justifyContent: "center",
        }}
      >

        <div
          style={{
            width: "230px",
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
  <a href="https://github.com/Tenzingo7" style={{ textDecoration: "none", color: "inherit" }}>
    <FaFacebookSquare
      className="hover:text-blue-400 text-[24px]" 
    />
  </a>
</div>
<div>
  <a href="https://github.com/Tenzingo7" style={{ textDecoration: "none", color: "inherit" }}>
    <FaInstagram
      className="hover:text-pink-400 text-[24px]" 
    />
  </a>
</div>
<div>
  <a href="https://github.com/Tenzingo7" style={{ textDecoration: "none", color: "inherit" }}>
    <GoMail
      className="hover:text-red-600 text-[24px]" 
    />
  </a>
</div>
<div>
  <a href="https://github.com/Tenzingo7" style={{ textDecoration: "none", color: "inherit" }} >
    <FaWhatsapp 
    className="hover:text-green-400 text-[24px]" 
    />
  </a>
</div>

        </div>
      </Container>
      <Container
        style={{ marginTop: "40px", display: "flex", justifyContent: "center" }}
      >
        <Navbar.Brand href="#home" style={{ color: "#ffffff" }}>
          In the work you should trust in
        </Navbar.Brand>
      </Container>
      <div className="text-[#292f33] text-[10px]">Hey you found me</div>
    </Navbar>
  );
};

export default Footer;
