import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout';
import Home from '../Pages/Home';
import About from '../Pages/About';

const MyRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='/' element={<Home />} />
        <Route path='/home' index element={<Home />} /> 
        <Route path='/about' element={<About />} />
      </Route>
    </Routes>
  );
}

export default MyRouter;
