import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "react-bootstrap/Nav";
import { useDispatch } from "react-redux";
import { setScrollTo } from "../redux/scrollSlice";

const Header = () => {
  const dispatch = useDispatch();

  const handleScrollToAbout = () => {
    dispatch(setScrollTo("about"));
  };
  return (
    <Nav
      activeKey="/home"
      className="border-b border-gray-400 fixed top-0 w-full bg-white bg-opacity-100"
    >
      <Nav.Item>
        <Nav.Link eventKey="disabled" disabled>
          <span className="fs-5 me-2 ">Tenzing Chhebe Sherpa</span>{" "}
          {/* Using fs-5 class to make the text larger */}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/home" className="fs-6 me-2 mt-1">
          Home
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link onClick={handleScrollToAbout} className="fs-6 me-2 mt-1">
          About
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-2" className="fs-6 me-2 mt-1">
          Contact
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default Header;
