import React from 'react'

const Qualification = () => {
  return (
    <div>
      <div className=''><spam>Qualification</spam></div>
    </div>
  )
}

export default Qualification
