import React from 'react'
import Qualification from './AboutComponents/Qualification'
import Projects from './AboutComponents/Projects'

const About = () => {
  return (
    <div>
      <div className='flex justify-center'><span className='text-3xl font-mono'>About</span></div>
      <div>Hellooooo
      i am tenzing
      nice to meet you</div>
      
      <div>
        how may i help ypu
      </div>
      <div>
        <Qualification/>
      </div>
      <div>
        <Projects/>
      </div>
    </div>
  )
}

export default About
