import React from "react";
import About from "./About";
import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setScrollTo } from "../redux/scrollSlice";
import Laptop from "../images/laptop.png";
import './home.css'

const Home = () => {
  const aboutRef = useRef(null);
  const scrollTo = useSelector((state) => state.scroll.scrollTo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (scrollTo === "about" && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
      dispatch(setScrollTo(null)); // Reset scroll state after scrolling
    }
  }, [scrollTo, dispatch]);

  return (
    <div className="mt-16">
      <div className="ml-5 flex justify-between">
        <div className="transition-colors duration-300 ease-in-out hover:text-white">
          <div className="text-[8px] mt-0 ml-7 transform rotate-0 ">
            Hello world
          </div>
          <div className="text-[8px] mt-[14px] transform rotate-90 absolute">
            Hello world
          </div>
          <div className="text-[8px] mt-[14px]  ml-[54px] transform rotate-90 absolute">
            Hello world
          </div>
          <div className="text-[8px] mt-10 ml-7  transform rotate-0 ">
            Hello world
          </div>
        </div>
        <div className="mr-10 transition-colors duration-300 ease-in-out hover:text-white">
          <div className="text-[8px] mt-0 ml-7 transform rotate-0 ">
            Hello world
          </div>
          <div className="text-[8px] mt-[14px] transform rotate-90 absolute">
            Hello world
          </div>
          <div className="text-[8px] mt-[14px]  ml-[54px] transform rotate-90 absolute">
            Hello world
          </div>
          <div className="text-[8px] mt-10 ml-7  transform rotate-0 ">
            Hello world
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-20 align-center h-[72vh]">
        <div className="mt-11">
          <h1 className=" text-6xl transition-colors duration-900 ease-in-out hover:text-sky-700 font-mono">In the work</h1>
          <p1 className='font-mono'>you should believe in</p1>
        </div>
        <div className="h-72 px-7 ">
        <span className="absolute mt-2 ml-56 "><p1 className="text-[10px] font-mono">Since ZOOS</p1></span>
          <span className="absolute mt-20 ml-24 "><h1 className="text-2xl font-mono">Experienced in: </h1></span>
          <span className="inner-heading absolute mt-32 ml-40"><h1 className=" text-2xl font-mono">
            FrontEnd development <br/>
            UI/UX Designer<br/>
            Back-End Developer <br/>
            Full-Stack Developer <br/>
            
            </h1></span>
          <img src={Laptop} alt="laptop" className="w-full h-full relative" />
          
        </div>
      </div>
      <div className="color-changing-text text-[4px] overflow-hidden whitespace-nowrap text-overflow-ellipsis">
          <p1>For The Lorem Lovers: Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae ullam nam, unde tempora animi eum consectetur facere, provident quod illo culpa ad tempore earum cum assumenda laudantium itaque deleniti voluptatem. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae ullam nam, unde tempora animi eum consectetur facere, provident quod illo culpa ad tempore earum cum assumenda laudantium Lorem ipsum dolor, sit amet consectetur adipisicing elit. Earum saepe quidem neque recusandae molestias voluptatum veritatis voluptate commodi eveniet, sint aperiam dolores ipsa iste, dicta ad asperiores hic. Reprehenderit, numquam. itaque deleniti voluptatem.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae ullam nam, unde tempora animi eum consectetur facere, provident quod illo culpa ad tempore earum cum assumenda laudantium itaque deleniti voluptatem. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestiae ullam nam, unde tempora animi eum consectetur facere, provident quod illo culpa ad tempore earum cum assumenda laudantium itaque deleniti voluptatem.</p1>
        </div>
      <div className="bg-slate-600 flex justify-center">
        <div><h3 className="text-white">Since</h3></div>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div>
      
      </div>
      <div ref={aboutRef} id="about-section">
        <About />
      </div>
      
    </div>
   
  );
};

export default Home;
