import React from "react";

const Projects = () => {
  return (
    <div className="mt-3">
      <div className="text-[2px] overflow-hidden w-full h-[2px] mb-6">
        <p1>
          <p1 className="text-red-100">AGAIN FOR MY LOREM LOVERS:</p1> Lorem
          ipsum dolor sit, amet consectetur adipisicing elit. Laborum odit,
          reprehenderit pariatur dignissimos molestiae laudantium in ex?
          Inventore omnis nesciunt expedita repudiandae. Officia incidunt quasi,
          qui ab enim minus sequi. Lorem ipsum dolor sit amet consectetur,
          adipisicing elit. Eos dolores, unde similique eligendi cumque enim,
          perspiciatis dolorum ipsum perferendis nemo error impedit qui rem
          reiciendis molestiae! Cum reprehenderit facilis commodi.Lorem ipsum
          dolor sit, amet consectetur adipisicing elit. Laborum odit,
          reprehenderit pariatur dignissimos molestiae laudantium in ex?
          Inventore omnis nesciunt expedita repudiandae. Officia incidunt quasi,
          qui ab enim minus sequi. Lorem ipsum dolor sit amet consectetur,
          adipisicing elit. Eos dolores, unde similique eligendi cumque enim,
          perspiciatis dolorum ipsum perferendis nemo error impedit qui rem
          reiciendis molestiae! Cum reprehenderit facilis commodi.Lorem ipsum
          dolor sit, amet consectetur adipisicing elit. Laborum odit,
          reprehenderit pariatur dignissimos molestiae laudantium in ex?
          Inventore omnis nesciunt expedita repudiandae. Officia incidunt quasi,
          qui ab enim minus sequi. Lorem ipsum dolor sit amet consectetur,
          adipisicing elit. Eos dolores, unde similique eligendi cumque enim,
          perspiciatis dolorum ipsum perferendis nemo error impedit qui rem
          reiciendis molestiae! Cum reprehenderit facilis commodi. Lorem ipsum
          dolor sit amet consectetur adipisicing elit. Odio, laudantium!
          Assumenda, autem. Accusantium esse similique non nostrum pariatur?
          Iusto incidunt delectus provident eum, doloribus inventore eveniet
          molestias voluptates. Modi, quo! Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Ad quod doloremque accusantium vero
          quidem blanditiis mollitia, atque distinctio non cum quisquam id
          libero dolorem culpa tempore fuga laborum! Eos, est. Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Iusto, debitis. Minima corporis
          eos illo veniam unde quisquam possimus quasi fuga. Repellendus est,
          non sit blanditiis suscipit mollitia quasi eum natus?
        </p1>
      </div>
      <h1>Projects</h1>
      <div class="container mx-auto px-4 py-8">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-20">
          <div class="flex flex-col justify-center items-center bg-white rounded-lg shadow-md rounded-2xl">
            <div class="h-72 w-full bg-slate-400 text-white flex justify-center items-center ">
              <span class="text-4xl">IMG</span>
            </div>
            <div class="p-4">
              <h2 class="text-xl font-semibold mb-2">Project 1</h2>
              <p class="text-sm">Yellow buddies</p>
            </div>
          </div>

          <div class="flex flex-col justify-center items-center bg-white rounded-lg shadow-md">
            <div class="h-72 w-full bg-slate-400 text-white flex justify-center items-center">
              <span class="text-4xl">IMG</span>
            </div>
            <div class="p-4">
              <h2 class="text-xl font-semibold mb-2">Project 2</h2>
              <p class="text-sm">I am a description</p>
            </div>
          </div>

          <div class="flex flex-col justify-center items-center bg-white rounded-lg shadow-md">
            <div class="h-72 w-full bg-slate-400 text-white flex justify-center items-center">
              <span class="text-4xl">IMG</span>
            </div>
            <div class="p-4">
              <h2 class="text-xl font-semibold mb-2">Project 3</h2>
              <p class="text-sm">I am a description</p>
            </div>
          </div>

          <div class="flex flex-col justify-center items-center bg-white rounded-lg shadow-md">
            <div class="h-72 w-full bg-slate-400 text-white flex justify-center items-center">
              <span class="text-4xl">IMG</span>
            </div>
            <div class="p-4">
              <h2 class="text-xl font-semibold mb-2">Project 4</h2>
              <p class="text-sm">I am a description</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
