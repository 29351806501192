import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import MyRouter from "./route/MyRouter";
import { Provider } from 'react-redux';
import { store } from './redux/store';

function App() {
  return (
    <div>
      <Provider store={store}>
      <BrowserRouter>
        <MyRouter />
      </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
