import Header from './Header'
import Footer from './Footer'
import { Outlet } from 'react-router-dom';

const Layout = (props) => {
  return (
    <div>
      <Header newNotification = {props.newNotification}/>
      <Outlet/>
      <Footer/>
    </div>
  )
}

export default Layout
